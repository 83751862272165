/**
 * @description 制定规则
*/

// 发行活动码为唯一标识
const CMap = {
    CR2023: {
        title: 'Ape World共创漫画数字藏品', // 活动名称
        adBg: require('@/assets/img/ape-world/cartoon_bg.png'),
        share: {
            bgImg: '',
            shareLink: ''
        },
        //数组：3D旋转的6张资源图，字符串: 本地图片资源
        sources: [
            require("@/assets/img/ape-world/cartoon_front.png"),
            require("@/assets/img/ape-world/cartoon_front.png"),
            require("@/assets/img/ape-world/cartoon_left.png"),
            require("@/assets/img/ape-world/cartoon_left.png"),
            require("@/assets/img/ape-world/cartoon_top.png"),
            require("@/assets/img/ape-world/cartoon_top.png")
        ],
        //3d背景图或背景色
        sourceBg: require('@/assets/img/ape-world/sale_bg.png'),
        //详情显示文本控制: 0: 不可使用积分，1: 不可使用积分，可使用专属优惠券
        couponPoint: 0, 
        orderType: 0, //支付时后端定义的订单类型
    },
    gulandRec: {
        // 只有合成没有发售
        title: 'GULand矿工回收',
    },
    GuLandBlindBox: {
        title: '矿工猴盲盒发售',
        adBg: '',
        share: null,
        sources: undefined,
        orderType: 9,
    },
    SPACEBlindBox: {
        title: '空间盲盒发售', 
        adBg: require("@/assets/img/spaceBeautifulAccount/sale-top.png"),
        share: null,
        sources: [
            require("@/assets/img/spaceBeautifulAccount/box-front.png"),
            require("@/assets/img/spaceBeautifulAccount/box-front.png"),
            require("@/assets/img/spaceBeautifulAccount/box-left.png"),
            require("@/assets/img/spaceBeautifulAccount/box-left.png"),
            require("@/assets/img/spaceBeautifulAccount/box-up.png"),
            require("@/assets/img/spaceBeautifulAccount/box-up.png")
        ],
        orderType: 10,
    },
    europeanCup: {
        title: '欧洲杯', // 活动名称
        adBg: require('@/assets/img/eurocup/top_bg.png'), //宣传背景图
        share: null, // 是否可分享
        sources: undefined,
        couponPoint: 1,
        orderType: 11, //支付时后端定义的订单类型
    },
    apeBindBox: {
        title: 'Ape World配件盲盒',
        adBg: require('@/assets/img/partsBlindbox/detail_bg_top.png'),
        share: null,
        sources: [
            require("@/assets/img/partsBlindbox/front-back.png"),
            require("@/assets/img/partsBlindbox/front-back.png"),
            require("@/assets/img/partsBlindbox/left-right.png"),
            require("@/assets/img/partsBlindbox/left-right.png"),
            require("@/assets/img/partsBlindbox/up-down.png"),
            require("@/assets/img/partsBlindbox/up-down.png")
        ],
        couponPoint: 0,
        orderType: 12,
    },
    healthPreservation: {
        title: '绿地康养藏品',
        adBg: require('@/assets/img/kangYangNFT/detail_bg_top.png'),
        share: null,
        sources: [
            require("@/assets/img/kangYangNFT/front.jpg"),
            require("@/assets/img/kangYangNFT/front.jpg"),
            require("@/assets/img/kangYangNFT/left.png"),
            require("@/assets/img/kangYangNFT/left.png"),
            require("@/assets/img/kangYangNFT/up.png"),
            require("@/assets/img/kangYangNFT/up.png")
        ],
        couponPoint: 0,
        orderType: 13,
    },
    industrial: {
        title: 'APE WORLD配件盲盒',
        adBg: require('@/assets/img/industrialApe/detail_bg_top.png'),
        share: null,
        sources: [
            require("@/assets/img/industrialApe/front.jpg"),
            require("@/assets/img/industrialApe/front.jpg"),
            require("@/assets/img/industrialApe/left.png"),
            require("@/assets/img/industrialApe/left.png"),
            require("@/assets/img/industrialApe/up.png"),
            require("@/assets/img/industrialApe/up.png")
        ],
        couponPoint: 0,
        orderType: 14,
    }
}

export {
    CMap,
};