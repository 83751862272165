import React, {useEffect, useState, useRef, Fragment} from "react";
import {Toast} from "antd-mobile";
import JsBridgeNew from "@/utils/jsbridgeNew";
import styles from './styles.module.scss';
import NFTHeader from "@/components/NFTHeader";
import {getUrlAllParams} from "@/utils/publicFun";
import {hideLoading, showLoading} from "@/components/loading";
import {goodsExchange} from "@/api/projectManager_self";


const OrderConfirmReal = (props) => {
    const {id, ...otherParam} = getUrlAllParams(window.location.search.slice(1));
    const [visible, setVisible] = useState(false)
    const [addressItem, setAddressItem] = useState()
    const [stateParam, setStateParam] = useState(otherParam);

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            const res = await JsBridgeNew.jKTServicesRequest({
                moduleName: 'MainPage',
                code: 101294,
                params: {}
            });
            if (res.status > 0) {
                let list = res.data || [];
                //查找默认地址
                let defaltAddress = list.find(item => item.isDefaltAddress == 1)
                if (!defaltAddress && list.length > 0) {
                    defaltAddress = list[0]
                }
                if (defaltAddress) {
                    setAddressItem(defaltAddress)
                }
            } else {
                Toast.show('请求失败')
            }
        } catch (error) {
            Toast.show(error || '请求失败')
        }
    }


    const onSubmit = async () => {
        console.log('a---',addressItem)
        if (addressItem.needComplete) {
            return Toast.show('请先完善地址信息');
        }
        duihuanClick();
        onClose()
    }

    const duihuanClick = async () => {
        let params = {goodsId: id, num: 1, spendItem: {type: 2, num: 1},...addressItem};
        console.log('params---',params)
        // 支付对象类型：2普通碎片，3变异兑换券
        try {
            showLoading()
            const res = await goodsExchange(params);
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            Toast.show('兑换成功');
            setTimeout(()=>{
                window.JsBridge.back();
            },2000)
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const onChangeAddress = async () => {
        if (window.JsBridge.hasWebViewBridge()) {
            const address = await JsBridgeNew.push('CustomWeb', {
                uri: process.env.REACT_APP_GKT_LINK + `/front/guapp/address/addressList?mode=1`,
                callback: true
            });
            console.log('address---', address)
            if (address) {
                setAddressItem(address)
            }
        } else {
            props.history.push('/front/guapp/address/addressList?mode=1')
        }

        onClose()
    }

    const onConfirmOrder = () => {
        if (addressItem.needComplete) {
            //完善地址信息
            Toast.show('请先完善地址信息')
        } else {
            setVisible(true)
        }

    }

    const onClose = () => {
        setVisible(false)
    }

    return (
        <div className={styles.activity_order_page}>
            <NFTHeader midText={'确认订单'}/>
            <div className={styles.body}>
                {/* 地址信息 */}
                <div className={styles.address_item} onClick={stateParam.orderId ? () => {
                } : onChangeAddress}>
                    <img src={require('@/assets/img/address_icon.png')} alt=""/>
                    {
                        addressItem ?
                            <div className={styles.address_center}>
                                <div className={styles.user_info}>
                                    <span>{addressItem.consigneeName || ''}</span>
                                    <span>{addressItem.consigneeNumber || ''}</span>
                                </div>
                                <div className={styles.address_info}>
                                    {addressItem.provinceName + addressItem.cityName + addressItem.areaName + (addressItem.streetName || '') + (addressItem.deliveryAddress || '')}
                                </div>
                            </div>
                            : <div className={styles.none_address}>{'您的收货地址为空，点击添加收货地址'}</div>

                    }

                    <div className={styles.right_arrow}>
                        {stateParam.orderId ? <></> : <img src={require('@/assets/img/more_icon.png')} alt=""/>}
                    </div>
                </div>
                {/* 商品信息 */}
                <div className={styles.shop_item}>
                    <div className={styles.item_top}>
                        <div className={styles.left}>
                            <img src={stateParam.goodsImg} alt=""/>
                        </div>
                        <div className={styles.right}>
                            <span>{decodeURI(stateParam.goodsName) || ''}</span>
                            <div className={styles.suipian_container}>
                                <div className={styles.bottomZS}>
                                    <img src={require('../../../imgs/icon_sp.png')} alt=""/>
                                    <p>{stateParam.suipian || 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.item_bottom}>
                        <div>配送服务</div>
                        <span>快递免邮</span>
                    </div>
                </div>
            </div>
            {
                stateParam.orderId ? null :
                    <div className={styles.bottom}>
                        {
                            addressItem ?
                                <div className={styles.button} onClick={onConfirmOrder}>{'立即兑换'}</div>
                                : <div className={`${styles.button} ${styles.disable}`}>{'提交订单'}</div>
                        }

                    </div>
            }
            <ConfirmModal visible={visible}
                          onClose={onClose}
                          addressItem={addressItem}
                          onEditAddress={onChangeAddress}
                          onSubmit={onSubmit}
            />
        </div>
    )
}

// 此处弹框和积分商城兑换弹框保持一致
const ConfirmModal = (props) => {
    const {visible, addressItem, onClose} = props
    return (
        <Fragment>
            {
                visible ?
                    <div className={styles.confirm_modal_mask}>
                        <div className={styles.modal_body}>
                            <div className={styles.modal_header}>
                                <div className={styles.modal_header_title}>核对地址</div>
                                <img src={require('@/assets/img/icon_close.png')} alt="" onClick={onClose}/>
                            </div>
                            <div className={styles.modal_content}>
                                <div className={styles.notice_info}>提交后收件人信息不可更改，请您仔细核对收件人信息哦</div>
                                <div className={styles.confirm_info}>
                                    <div>
                                        <span>姓名：</span>
                                        <span>{addressItem.consigneeName || ''}</span>
                                    </div>
                                    <div>
                                        <span>电话：</span>
                                        <span>{addressItem.consigneeNumber || ''}</span>
                                    </div>
                                    <div>
                                        <span>地址：</span>
                                        <span>{addressItem.provinceName + addressItem.cityName + addressItem.areaName + (addressItem.streetName || '') + (addressItem.deliveryAddress || '')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.modal_footer}>
                                <div onClick={props.onEditAddress}>{'修改信息'}</div>
                                <div onClick={props.onSubmit}>{'确认'}</div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </Fragment>
    )
}

export default OrderConfirmReal;
