/**
 * @description 欧洲杯相关接口
*/
import requests from '@/utils/requests_self';
import { queryDefCode, getActivityTime } from "@/api/projectManager_self";
import moment from "moment";

const mock = false;

let TotalCount = '00000000';
let startDate;
class Service {

    static activityCode = 'europeanCup';

    /**
     * @description 获取欧洲杯活动初始化数据
     * @param {*} params 
     * @returns
    */
    static getInitDurocupData = async (params={})=> {
        if(mock) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if(!startDate) {
                        startDate = new Date();
                    }
                    const currentTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    const startTime = moment(startDate.getTime() + 10000).format('YYYY-MM-DD HH:mm:ss');
                    const endTime = moment(startDate.getTime() + 100000).format('YYYY-MM-DD HH:mm:ss');

                    if(new Date(currentTime).getTime() > new Date(startTime).getTime()) {
                        const endV = Number(TotalCount);
                        const random = Math.floor(Math.random() * 1000) + 1;
                        let endString = (endV + random).toString();
                        console.log(`random: ${random}, endValue: ${endV}, endString: ${endString}`)
                        if (endString.length < TotalCount.length) {
                            endString = endString.padStart(TotalCount.length, '0')
                        }
                        TotalCount = endString;
                    }
                    
                    resolve({
                        "code": '000000',
                        "message": "success",
                        "data": {
                            activityCode: "eurocup",
                            activityName: "欧洲杯",
                            activityDesc: "欧洲杯",
                            activityStatus: "1",
                            currentTime: currentTime,
                            startTime: startTime,
                            endTime: endTime,
                            maxPool: TotalCount,
                            teamList: [
                                {id: '1', key: '1', country: '法国', imgUrl: require('@/assets/img/pic_c.png'), collectionId: '20', soldCount: 200, hasCount: 100},
                                {id: '2', key: '1', country: '德国', imgUrl: require('@/assets/img/pic_a.png'), collectionId: '20', soldCount: 200, hasCount: 100},
                                {id: '3', key: '1', country: '西班牙', imgUrl: require('@/assets/img/pic_b.png'), collectionId: '20', soldCount: 200, hasCount: 100},
                                {id: '4', key: '1', country: '葡萄牙', imgUrl: require('@/assets/img/pic_a.png'), collectionId: '20', soldCount: 200, hasCount: 100},
                                {id: '5', key: '1', country: '意大利', imgUrl: require('@/assets/img/pic_c.png'), collectionId: '20', soldCount: 200, hasCount: 100},
                                {id: '6', key: '1', country: '巴西', imgUrl: require('@/assets/img/pic_a.png'), collectionId: '20', soldCount: 200, hasCount: 100},
                                {id: '7', key: '1', country: '阿根廷', imgUrl: require('@/assets/img/pic_b.png'), collectionId: '20', soldCount: 200, hasCount: 100},
                                {id: '8', key: '1', country: '荷兰', imgUrl: require('@/assets/img/pic_a.png'), collectionId: '20', soldCount: 200, hasCount: 100},
                            ]
                        }
                    })
                },1000)
            })
        }else {
            const userInfo = JSON.parse(window.localStorage.getItem('userInfo') ?? "{}");
            params = { activityCode: Service.activityCode, clientId: userInfo?.clientId, ...params };
            const res = await getActivityTime(params);
            const {startTime, endTime, currentTime} = res?.data ?? {};

            const result = await requests.post('/customer/europeanCup/activityInit', JSON.stringify(params));
            if(result) {
                const data = result.data ?? {};
                result.data = {
                    ...data,
                    maxPool: (data.maxPool + '').padStart(TotalCount.length, '0'),
                    startTime: startTime,
                    endTime: endTime,
                    currentTime: currentTime
                }
            }
            
            return result;
        }
        
    }

    /**
     * @description 获取活动规则
     * @param {*} params 
     * @returns
    */
    static getActivityRules = async ()=> {
        if(mock) {
            return Promise.resolve({
                code: '000000',
                message: "success",
                data: {
                    result: {
                        defCode: {
                            codeAlias: '欧洲杯活动规则',
                            otherSign: '欧洲杯活动的详细规则如下：xxxxxx'
                        }
                    }
                }

            })
        }
        return await queryDefCode({codeType: 'activityRule', code: Service.activityCode});
    }
}

export default Service;