// 登录模块

// Home模块
import Home from "../view/home"
import SaleDetail from "../view/saleDetail"
import SalePay from "../view/salePay"

import APE from "../view/ape";

// 我的模块
import Mine from "../view/mine"
import MyCollection from "../view/myCollection"
import MyCollectionSearch from "../view/myCollectionSearch"
import MyCollectionDetail from "../view/myCollectionDetail"
import SecondCreationAgreement from "../view/secondCreationAgreement"

import {CollectionList, CollectionVote,EditSuggest,CollectionRaffle, RaffleShare,CreateCommunity} from '@/view/myCollectionVote/index'


import CartoonCollection from '@/view/cartoonCollection/index'
import UpgradeHistory from '@/view/cartoonCollection/upgradeHistory'

import CartoonSaleDetail from '@/view/apeWorld/cartoonSaleDetail'

import MyOrder from "../view/myOrder"
import MyOrderDetail from "../view/myOrderDetail"
import MyGroupOrder from "../view/myGroupOrder"
import GroupOrderDetail from "../view/myGroupOrder/groupOrderDetail"

import MyMysteryBox from "../view/myMysteryBox"
import MyAgreement from "@/view/myAgreement"
import DonationInfo from '../view/donationInfo'
import Error from "../view/Error"
import DonationSuccess from '../view/donationSuccess';
import ReceiveDonation from '../view/receiveDonation';
import ReceiveResult from '../view/receiveResult';
import HotAss from '../view/hot';
import TransferRecord from '../view/transferRecord';
import ApeWorldSaleDetail from '../view/apeWorldSaleDetail';
import ApeWorldSalePay from "../view/apeWorldSalePay";
import AllSaintsDaySaleDetail from "../view/allSaintsDaySaleDetail";
import {ShopStageHome} from "@/view/shopStage1";
import ShopCartSalePay from "@/view/shopCartSalePay";
import ShopProductDetail from "@/view/shopStage1/views/shopProductDetail";
import ShopPaySuccess from "@/view/shopPaySuccess";
import {EggHatch,} from "@/view/eggHatch";
import {EggHatchProgress} from "@/view/eggHatch/views/eggHatchProgress";
import EggHatchSuccess from "@/view/eggHatch/views/eggHatchSuccess";
import EggHatchProductDetail from "@/view/eggHatch/views/eggHatchProductDetail";
import variationApe from "@/view/variationApe"

import CartoonShare from '@/view/share/CartoonShare/index'
import BlockChainQuery from "@/view/blockChainQuery";
import {BlockChainAdrInfo, BlockChainInfo} from "@/view/blockChainQuery/components/blockChainAdrInfo";
import {BlockChainColInfo} from "@/view/blockChainQuery/components/blockChainColInfo";
import {BoringApeClub} from "@/view/boringApeClub";
import {NftSetting, PwdResert, PwdSetting} from "@/view/nftSetting";
import partsRecycling from "@/view/partsRecycling";

import RecoveryLand from "@/view/collectionRecoveryLand";

import MyCollectionSearchResult from "@/view/myCollectionSearch/collectionSearchResult";
import MyCollectionSearchNavigation from "@/view/myCollectionSearch/collectionSearchNavigation";

import MyCoupon from "@/view/myCoupon";
import Pay from "@/view/pay";
import PaySuccess from "@/view/pay/success/index";
import guessingActy from "@/view/guessingActy";
import {MoviegoerTicket} from "@/view/myCollectionVote";
import partsRecyclingUnity from "@/view/partsRecyclingUnity";
import colCompound from "@/view/colCompound";
import EnviroProDetail from "@/view/enviroProDetail";
import ExchangeSucc from "@/view/enviroProDetail/exchangeSucc";

// LandGame
import LandGame from "@/view/landGame"
import LandDetail from "@/view/landGame/landDetail"
import EleCompoundHome from "@/view/eleCompound/home";
import JewelLand from "@/view/landGame/jewelLand";
import LeaseCenter from "@/view/landGame/leaseCenter";
import RentOut from "@/view/landGame/leaseCenter/rentOut";
import RentOutDetail from "@/view/landGame/leaseCenter/rentOut/detail";

import WorkapeBlindbox from "@/view/workApeBlindbox";
import SpaceBeautifulAccount from "@/view/spaceBeautifulAccount"
//欧洲杯
import Eurocup from "@/view/eurocup";
// 配件盲盒
import PartsBlindbox from "@/view/apeWorld/partsBlindbox";
import KangYangNFT from "@/view/kangYangNFT";
// 工业猿
import IndustrialApe from "@/view/industrialApe";
// 盲盒转宝石
import BoxToStone from "@/view/boxToStone";

function routerInteceptor(params) {
}

const routes = [
    {
        path: "/",
        element: <Home/>,
        title: "首页",
    },
    {
        path: "*",
        element: <Error/>,
        title: "error",
    },
    {
        path: "/notFond",
        element: <Error/>,
        title: "404",
    },
    {
        path: "/serverErr",
        element: <Error context="活动过于火爆，请您稍后重试~"/>,
        title: "server error",
    },
    {
        path: "/mine",
        element: <Mine/>,
        title: "我的",
    },

    // NOTE: Home模块
    {
        path: "/saleDetail",
        element: <SaleDetail/>,
        title: "发售详情",
    },
    {
        path: "/salePay",
        element: <SalePay/>,
        title: "订单支付",
    },
    {
        path: "/pay",
        element: <Pay />,
        title: "订单支付",
    },
    {
        path: "/pay/success",
        element: <PaySuccess />,
        title: "支付成功",
    },
    {
        path: "/ape/PaySuccess",
        element: <APE.PaySuccess/>,
        title: "支付成功",
    },
    {
        path: "/ape/saleDetail",
        element: <APE.SaleDetail/>,
        title: "发售详情",
    },
    {
        path: "/ape/PayPage",
        element: <APE.PayPage/>,
        title: "订单支付",
    },
    {
        path: "/ape/DefaultPay",
        element: <APE.DefaultPay/>,
        title: "订单支付",
    },

    // NOTE: 我的模块
    {
        path: "/myCollection",
        element: <MyCollection/>,
        title: "我的数字资产",
    },
    {
        path: "/myCollectionSearch",
        element: <MyCollectionSearch/>,
        title: "藏品搜索",
    },
    {
        path: "/myCollectionSearchNavigation",
        element: <MyCollectionSearchNavigation/>,
        title: "搜索结果页面",
    },
    {
        path: "/myCollectionSearchResult",
        element: <MyCollectionSearchResult/>,
        title: "藏品搜索导航页面",
    },
    {
        path: "/donationInfo",
        element: <DonationInfo/>,
        title: "转赠信息填写",
    },
    {
        path: "/donationSuccess",
        element: <DonationSuccess/>,
        title: "已成功发起转赠",
    },
    {
        path: "/receiveDonation",
        element: <ReceiveDonation/>,
        title: "接收转赠",
    },
    {
        path: "/receiveResult",
        element: <ReceiveResult/>,
        title: "",
    },
    {
        path: "/transferRecord",
        element: <TransferRecord/>,
        title: "转赠记录",
    },
    {
        path: "/myAgreement",
        element: <MyAgreement/>,
        title: "用户协议",
    },
    {
        path: "/myCollectionDetail",
        element: <MyCollectionDetail/>,
        title: "藏品详情",
    },
    {
        path: "/secondCreationAgreement",
        element: <SecondCreationAgreement/>,
        title: "二次创作及商业使用权说明",
    },
    {
        path: "/myMysteryBox",
        element: <MyMysteryBox/>,
        title: "盲盒抽奖",
    },
    {
        path: "/myOrder",
        element: <MyOrder/>,
        title: "我的订单",
    },
    {
        path: "/myOrderDetail",
        element: <MyOrderDetail/>,
        title: "订单详情",
    },
    {
        path: "/myGroupOrder",
        element: <MyGroupOrder/>,
        title: "我的订单",
    },
    {
        path: "/myGroupOrder/groupOrderDetail",
        element: <GroupOrderDetail/>,
        title: "订单详情",
    },
    {
        path: "/myCoupon",
        element: <MyCoupon />,
        title: "我的优惠券",
    },
    {
        path: "/hot",
        element: <HotAss/>,
        title: "稍后重试",
    },
    {
        path: "/apeWorldSaleDetail",
        element: <ApeWorldSaleDetail/>,
        title: "发售详情",
    },
    {
        path: "/allSaintsDaySaleDetail",
        element: <AllSaintsDaySaleDetail/>,
        title: "发售详情",
    },
    {
        path: "/apeWorldSalePay",
        element: <ApeWorldSalePay/>,
        title: "订单支付",
    },
    {
        path: "/shopStageHome",
        element: <ShopStageHome/>,
        title: "购物车首页",
    },
    {
        path: "/shopCartSalePay",
        element: <ShopCartSalePay/>,
        title: "订单支付",
    },
    {
        path: "/shopProductDetail",
        element: <ShopProductDetail/>,
        title: "商品详情",
    },

    {
        path: "/shopPaySuccess",
        element: <ShopPaySuccess/>,
        title: "支付成功",
    },
    {
        path:'/landGame',
        element: <LandGame></LandGame>,
        title:'元宇宙土地'
    },
    {
      path:'/landGame/landDetail',
      element: <LandDetail></LandDetail>,
      title:'元宇宙土地'
    },
    {
        path: "/landGame/jewelLand",
        element: <JewelLand />,
        title: "",
    },
    {
        path: "/landGame/leaseCenter",
        element: <LeaseCenter />,
        title: "",
    },
    {
        path: "/landGame/rentOut",
        element: <RentOut />,
        title: "",
    },
    {
        path: "/landGame/rentOutDetail",
        element: <RentOutDetail/>,
        title: "",
    },
    {
        path: "/eggHatch",
        element: <EggHatch/>,
        title: "数字藏品",
    },
    {
        path: "/eggHatchProgress",
        element: <EggHatchProgress/>,
        title: "数字藏品",
    },
    {
        path: "/eggHatchSuccess",
        element: <EggHatchSuccess/>,
        title: "数字藏品",
    },
    {
        path: "/eggHatchProductDetail",
        element: <EggHatchProductDetail/>,
        title: "数字藏品",
    },
    {
        path: "/myCollectionVote/collectionList",
        element: <CollectionList/>,
        title: "共创意见征集",
    },
    {
        path: "/myCollectionVote/collectionVote",
        element: <CollectionVote/>,
        title: "Ape World -共创意见征集",
    },
    {
        path: "/myCollectionVote/editSuggest",
        element: <EditSuggest/>,
        title: "Ape World -共创意见征集",
    },
    {
        path: "/cartoonCollection",
        element: <CartoonCollection/>,
        title: "共创漫画数字藏品",
    },
    {
        path: "/cartoonCollection/upgradeHistory",
        element: <UpgradeHistory/>,
        title: "升级记录",
    },
    {
        path: "/apeWorld/cartoonSaleDetail",
        element: <CartoonSaleDetail/>,
        title: "共创漫画发行详情",
    },
    {
        path: "/apeWorld/paypage",
        element: <APE.PayPage/>,
        title: "订单支付",
    },
    {
        path: "/share/cartoonShare",
        element: <CartoonShare/>,
        title: "共创漫画分享",
    },
    {
        path: "variationApe/home",
        element: <variationApe.VariationHome/>,
        title: "",
    },
    {
        path: "variationApe/medicine",
        element: <variationApe.VariationMedicine/>,
        title: "变异药水",
    },
    {
        path: "variationApe/share",
        element: <variationApe.VariationApeShare/>,
        title: "",
    },
    {
        path: "variationApe/invite/success",
        element: <variationApe.VariationApeInviteSuccess/>,
        title: "",
    },
    {
        path: "variationApe/compose",
        element: <variationApe.ApeCompose/>,
        title: "变异无聊猿",
    },
    {
        path: "variationApe/medicine/record",
        element: <variationApe.VariationRecord/>,
        title: "药水合成记录",
    },
    {
        path: "variationApe/saleDetail",
        element: <variationApe.VariationApeSaleDetail/>,
        title: "",
    },
    {
        path: "/blockChainQuery",
        element: <BlockChainQuery/>,
        title: "链上查询",
    },
    {
        path: "/blockChainAdrInfo",
        element: <BlockChainAdrInfo/>,
        title: "区块链地址信息",
    },
    {
        path: "/blockChainColInfo",
        element: <BlockChainColInfo/>,
        title: "区块链藏品信息",
    },
    {
        path: "/boringApeClub",
        element: <BoringApeClub/>,
        title: "dodo社区",
    },
    {
        path: "/nftSetting",
        element: <NftSetting/>,
        title: "数字藏品设置",
    },
    {
        path: "/pwdSetting",
        element: <PwdSetting/>,
        title: "数字藏品设置",
    },
    {
        path: "/pwdResert",
        element: <PwdResert/>,
        title: "重置密码",
    },
    {
        path: "/partsRecycling/home",
        element: <partsRecycling.PartsRecyclingHome/>,
        title: "",
    },
    {
        path: "/partsRecycling/record",
        element: <partsRecycling.RecyclingRecord/>,
        title: "回收记录",
    },
    {
        path: "/partsRecycling/recovery",
        element: <partsRecycling.RecoveryParts/>,
        title: "回收配件",
    },
    {
        path: "/partsRecycling/share",
        element: <partsRecycling.RecyclingShareSuccess/>,
        title: "",
    },
    {
        path: "/partsRecycling/partsRecovery",
        element: <partsRecycling.PartsRecovery/>,
        title: "中奖记录",
    },
    {
        path: "/collectionRecoveryLand/home",
        element: <RecoveryLand.Home/>,
        title: "藏品回收",
    },
    {
        path: "/collectionRecoveryLand/recycling",
        element: <RecoveryLand.Recycling/>,
        title: "藏品回收",
    },
    {
        path: "/collectionRecoveryLand/recoveryRecord",
        element: <RecoveryLand.RecoveryRecord/>,
        title: "回收记录",
    },
    {
      path: "/myCollectionVote/share",
      element: <RaffleShare />,
      title: "",
    },
    {
        path: "/myCollectionVote/collectionRaffle",
        element: <CollectionRaffle/>,
        title: "积分抽奖",
    },
    {
        path: "/myCollectionVote/createCommunity",
        element: <CreateCommunity/>,
        title: "Ape World共创社区",
    },
    {
        path: "/guessingActy/home",
        element: <guessingActy.GuessingActyHome/>,
        title: "",
    },
    {
        path: "/guessingActy/getCoupon",
        element: <guessingActy.GetCoupon/>,
        title: "",
    },
    {
        path: "/guessingActy/exchangeRecord",
        element: <guessingActy.GuessingExchangeRecord />,
        title: "兑换纪录",
    },
    {
        path: "/guessingActy/rule",
        element: <guessingActy.GuessingActyRule/>,
        title: "竞猜攻略",
    },
    {
        path: "/guessingActy/exchangeStore",
        element: <guessingActy.GuessingExchangeStore/>,
        title: "兑换商城",
    },
    {
        path: "/guessingActy/exchangeOrder",
        element: <guessingActy.GuessingExchangeOrder />,
        title: "兑换订单",
    },
    {
        path: "/guessingActy/exchangeOrderDetails",
        element: <guessingActy.GuessingExchangeOrderDetails/>,
        title: "订单详情",
    },
    {
        path: "/guessingActy/exchangeRealOrderDetails",
        element: <guessingActy.GuessingExchangeRealOrderDetails/>,
        title: "订单详情",
    },
    {
        path: "/guessingActy/enshrine",
        element: <guessingActy.GuessingEnshrineDetails />,
        title: "绿地变异无聊猿数字藏品",
    },
    {
        path: "/guessingActy/sessionDetail",
        element: <guessingActy.SessionDetail/>,
        title: "竞猜详情",
    },
    {
        path: "/guessingActy/allSession",
        element: <guessingActy.AllSession/>,
        title: "全部场次",
    },
    {
        path: "/guessingActy/orderConfirmVirtual",
        element: <guessingActy.OrderConfirmVirtual/>,
        title: "确认订单",
    },
    {
        path: "/guessingActy/orderConfirmReal",
        element: <guessingActy.OrderConfirmReal/>,
        title: "确认订单",
    },
    {
        path: "/myCollectionVote/moviegoerTicket",
        element: <MoviegoerTicket/>,
        title: "观演人信息",
    },
    {
        path: "/partsRecyclingUnity/home",
        element: <partsRecyclingUnity.PartsRecyclingHomeUnity/>,
        title: "",
    },
    {
        path: "/partsRecyclingUnity/recycleRecord",
        element: <partsRecyclingUnity.RecycleRecord/>,
        title: "",
    },
    {
        path: "/colCompound/home",
        element: <colCompound.ColCompoundHome/>,
        title: "藏品合成",
    },
    {
        path: "/colCompound/test",
        element: <colCompound.Test/>,
        title: "测试",
    },
    {
        path: "/enviroProDetail",
        element: <EnviroProDetail/>,
        title: "",
    },
    {
        path: "/exchangeSucc",
        element: <ExchangeSucc/>,
        title: "兑换成功",
    },
    {
        path: "/eleCompoundHome",
        element: <EleCompoundHome/>,
        title: "",
    },
    {
      path: "/workapeBlindbox/home",
      element: <WorkapeBlindbox.BlindboxHome/>,
      title: "",
    },
    {
      path: "/workapeBlindbox/upgradeRecord",
      element: <WorkapeBlindbox.UpgradeRecord/>,
      title: "",
    },
    {
      path: "/workapeBlindbox/upgrade",
      element: <WorkapeBlindbox.UpgradeS/>,
      title: "",
    },
    {
      path: "/spaceBeautifulAccount",
      element: <SpaceBeautifulAccount/>,
      title: "",
    },
    {
        path: "/eurocup/home",
        element: <Eurocup.Home/>,
        title: "欧洲杯",
    },
    {
        path: "/apeWorld/partsBlindbox/home",
        element: <PartsBlindbox.Home/>,
        title: "ape world 配件盲盒",
    },
    {
        path: "/apeWorld/partsBlindbox/up-exchange",
        element: <PartsBlindbox.UpExchange/>,
        title: "兑换",
    },
    {
        path: "/apeWorld/partsBlindbox/exchangeHistory",
        element: <PartsBlindbox.ExchangeHistory/>,
        title: "兑换记录",
    },
    {
        path: "/kangyang-nft/lanhu",
        element: <KangYangNFT.LanHu/>,
        title: "蓝湖",
    },
    {
        path: "/kangyang-nft/home",
        element: <KangYangNFT.Home/>,
        title: "康养藏品",
    },
    {
        path: "/kangyang-nft/mergeHistory",
        element: <KangYangNFT.MergeHistory/>,
        title: "权益获得记录",
    },
    {
        path: "/industrialApe/home",
        element: <IndustrialApe.Home/>,
        title: "工业猿",
    },
    {
        path: "/industrialApe/synthesis",
        element: <IndustrialApe.Synthesis/>,
        title: "工业猿合成",
    },
    {
        path: "/industrialApe/synthesisRecord",
        element: <IndustrialApe.SynthesisRecord/>,
        title: "工业猿合成记录",
    },
    {
        path: "/boxToStone/home",
        element: <BoxToStone.Home/>,
        title: "盲盒转宝石",
    },
    {
        path: "/boxToStone/goodsDetail",
        element: <BoxToStone.GoodsDetail/>,
        title: "商品详情",
    },
    {
        path: "/boxToStone/confirmOrder",
        element: <BoxToStone.ConfirmOrder/>,
        title: "确认订单",
    },
    {
        path: "/boxToStone/batchTransform",
        element: <BoxToStone.BatchTransform/>,
        title: "批量转化",
    },
    {
        path: "/boxToStone/buyHistory",
        element: <BoxToStone.BuyHistory/>,
        title: "购买记录",
    },
    {
        path: "/boxToStone/paySuccess",
        element: <BoxToStone.PaySuccess/>,
        title: "支付成功",
    }
]

export default routes
