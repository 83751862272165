const sequenceKeys = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

const pic_a = require('@/assets/img/pic_a.png');
const pic_b = require('@/assets/img/pic_b.png');
const pic_c = require('@/assets/img/pic_c.png');
const pic_d = require('@/assets/img/pic_d.png');
const pic_e = require('@/assets/img/pic_e.png');

const FuQuestionList = [
    {
        title: '画风以图片形式进行选择：', //标题
        require: true, //是否必选
        type: 'img', //类型
        multiple: 1, //能选几个
        list: [
            {
                title: '潮流国风', sort: 0, vote: 0, isVote: false, id: 0,
                url: pic_a,
                answerId: '0-0',
            },
            {
                title: '厚涂', sort: 1, vote: 0, isVote: false, id: 1,
                url: pic_b,
                answerId: '0-1',
            },
            {
                title: '扁平插画', sort: 2, vote: 0, isVote: false, id: 2,
                url: pic_c,
                answerId: '0-2',
            },
            {
                title: '像素风', sort: 3, vote: 0, isVote: false, id: 3,
                url: pic_d,
                answerId: '0-3',
            },
            {
                title: '可爱卡通', sort: 4, vote: 0, isVote: false, id: 4,
                url: pic_e,
                answerId: '0-4',
            }
        ]
    },
    {
        title: '您觉得蝙蝠的性别是什么？',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '男', sort: 0, vote: 0, isVote: false, id: 5, answerId: '1-0',
            },
            {
                title: '女', sort: 1, vote: 0, isVote: false, id: 6, answerId: '1-1',
            }
        ]
    },
    {
        title: '您觉得您想象中的蝙蝠会是什么人设？',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '个性冷酷', sort: 0, vote: 0, isVote: false, id: 7, answerId: '2-0',
            },
            {
                title: '天才少年', sort: 1, vote: 0, isVote: false, id: 8, answerId: '2-1',
            },
            {
                title: '富二代', sort: 1, vote: 0, isVote: false, id: 91, answerId: '2-2',
            },
            {
                title: '极简主义', sort: 1, vote: 0, isVote: false, id: 92, answerId: '2-3',
            },
            {
                title: 'IT大神', sort: 1, vote: 0, isVote: false, id: 10, answerId: '2-4',
            }
        ]
    },
    {
        title: '如果无聊猿与蝠鹿鱼四人组生活在中国，您觉得蝙蝠会来自哪里？',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '北上广深', sort: 0, vote: 0, isVote: false, id: 11, answerId: '3-0',
            },
            {
                title: '东北', sort: 1, vote: 0, isVote: false, id: 12, answerId: '3-1',
            },
            {
                title: '江南', sort: 1, vote: 0, isVote: false, id: 13, answerId: '3-2',
            },
            {
                title: '港澳台', sort: 1, vote: 0, isVote: false, id: 14, answerId: '3-3',
            },
            {
                title: '川渝地区', sort: 1, vote: 0, isVote: false, id: 15, answerId: '3-4',
            },
            {
                title: '青藏高原', sort: 1, vote: 0, isVote: false, id: 16, answerId: '3-5',
            },
            {
                title: '大西北', sort: 1, vote: 0, isVote: false, id: 17, answerId: '3-6',
            }
        ]
    },
    {
        title: '如果无聊猿与蝠鹿鱼四人组生活在您的身边， 您觉得蝙蝠的职业会是什么?',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '黑客', sort: 0, vote: 0, isVote: false, id: 18, answerId: '4-0',
            },
            {
                title: '游戏开发', sort: 1, vote: 0, isVote: false, id: 19, answerId: '4-1',
            },
            {
                title: '手工艺者', sort: 1, vote: 0, isVote: false, id: 20, answerId: '4-2',
            },
            {
                title: '飞行员', sort: 1, vote: 0, isVote: false, id: 21, answerId: '4-3',
            },
            {
                title: '医药研发', sort: 1, vote: 0, isVote: false, id: 22, answerId: '4-4',
            }
        ]
    },
    {
        title: '根据以上您选择的设定，您觉得蝙蝠应该叫什么？类似无聊猿之类的名字，也可以是类似蝙蝠XX的其他格式等（仅做参考，我们会根据性格设定挑选）',
        require: true,
        type: 'input',
        input: '',
        id: 99,
    },
    {
        title: '对于以上所有的问题，和其他没有问到的问题，欢迎在这里写下您的意见。',
        require: false,
        multlines: true,
        type: 'input',
        input: '',
        id: 100,
    }
]

const luQuestionList = [
    {
        title: '画风以图片形式进行选择：', //标题
        require: true, //是否必选
        type: 'img', //类型
        multiple: 1, //能选几个
        list: [
            {
                title: '潮流国风', sort: 0, vote: 0, isVote: false, id: 0,
                url: pic_a,
                answerId: '0-0',
            },
            {
                title: '厚涂', sort: 1, vote: 0, isVote: false, id: 1,
                url: pic_b,
                answerId: '0-1',
            },
            {
                title: '扁平插画', sort: 2, vote: 0, isVote: false, id: 2,
                url: pic_c,
                answerId: '0-2',
            },
            {
                title: '像素风', sort: 3, vote: 0, isVote: false, id: 3,
                url: pic_d,
                answerId: '0-3',
            },
            {
                title: '可爱卡通', sort: 4, vote: 0, isVote: false, id: 4,
                url: pic_e,
                answerId: '0-4',
            }
        ]
    },
    {
        title: '您觉得鹿的性别是什么？',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '男', sort: 0, vote: 0, isVote: false, id: 5, answerId: '1-0',
            },
            {
                title: '女', sort: 1, vote: 0, isVote: false, id: 6, answerId: '1-1',
            }
        ]
    },
    {
        title: '您觉得您想象中的鹿会是什么人设？',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '领袖气质', sort: 0, vote: 0, isVote: false, id: 7, answerId: '2-0',
            },
            {
                title: '浪漫主义', sort: 1, vote: 0, isVote: false, id: 8, answerId: '2-1',
            },
            {
                title: '社会大哥', sort: 1, vote: 0, isVote: false, id: 91, answerId: '2-2',
            },
            {
                title: '时尚先锋', sort: 1, vote: 0, isVote: false, id: 92, answerId: '2-3',
            },
            {
                title: '运动健将', sort: 1, vote: 0, isVote: false, id: 10, answerId: '2-4',
            }
        ]
    },
    {
        title: '如果无聊猿与蝠鹿鱼四人组生活在中国，您觉得鹿会来自哪里？',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '北上广深', sort: 0, vote: 0, isVote: false, id: 11, answerId: '3-0',
            },
            {
                title: '东北', sort: 1, vote: 0, isVote: false, id: 12, answerId: '3-1',
            },
            {
                title: '江南', sort: 1, vote: 0, isVote: false, id: 13, answerId: '3-2',
            },
            {
                title: '港澳台', sort: 1, vote: 0, isVote: false, id: 14, answerId: '3-3',
            },
            {
                title: '川渝地区', sort: 1, vote: 0, isVote: false, id: 15, answerId: '3-4',
            },
            {
                title: '青藏高原', sort: 1, vote: 0, isVote: false, id: 16, answerId: '3-5',
            },
            {
                title: '大西北', sort: 1, vote: 0, isVote: false, id: 17, answerId: '3-6',
            }
        ]
    },
    {
        title: '如果无聊猿与蝠鹿鱼四人组生活在您的身边， 您觉得鹿的职业会是什么?',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '律师', sort: 0, vote: 0, isVote: false, id: 18, answerId: '4-0',
            },
            {
                title: '医生', sort: 1, vote: 0, isVote: false, id: 19, answerId: '4-1',
            },
            {
                title: '侦探', sort: 1, vote: 0, isVote: false, id: 20, answerId: '4-2',
            },
            {
                title: '运动员', sort: 1, vote: 0, isVote: false, id: 21, answerId: '4-3',
            },
            {
                title: '酒吧老板', sort: 1, vote: 0, isVote: false, id: 22, answerId: '4-4',
            }
        ]
    },
    {
        title: '根据以上您选择的设定，您觉得鹿应该叫什么？类似无聊猿之类的名字，也可以是类似鹿XX的其他格式等（仅做参考，我们会根据性格设定挑选）',
        require: true,
        type: 'input',
        input: '',
        id: 99,
    },
    {
        title: '对于以上所有的问题，和其他没有问到的问题，欢迎在这里写下您的意见。',
        require: false,
        multlines: true,
        type: 'input',
        input: '',
        id: 100,
    }
]

const YuQuestionList = [
    {
        title: '画风以图片形式进行选择：', //标题
        require: true, //是否必选
        type: 'img', //类型
        multiple: 1, //能选几个
        list: [
            {
                title: '潮流国风', sort: 0, vote: 0, isVote: false, id: 0,
                url: pic_a,
                answerId: '0-0',
            },
            {
                title: '厚涂', sort: 1, vote: 0, isVote: false, id: 1,
                url: pic_b,
                answerId: '0-1',
            },
            {
                title: '扁平插画', sort: 2, vote: 0, isVote: false, id: 2,
                url: pic_c,
                answerId: '0-2',
            },
            {
                title: '像素风', sort: 3, vote: 0, isVote: false, id: 3,
                url: pic_d,
                answerId: '0-3',
            },
            {
                title: '可爱卡通', sort: 4, vote: 0, isVote: false, id: 4,
                url: pic_e,
                answerId: '0-4',
            }
        ]
    },
    {
        title: '您觉得鱼的性别是什么？',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '男', sort: 0, vote: 0, isVote: false, id: 5, answerId: '1-0',
            },
            {
                title: '女', sort: 1, vote: 0, isVote: false, id: 6, answerId: '1-1',
            }
        ]
    },
    {
        title: '您觉得您想象中的鱼会是什么人设？',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '搞笑担当', sort: 0, vote: 0, isVote: false, id: 7, answerId: '2-0',
            },
            {
                title: '游戏天坑', sort: 1, vote: 0, isVote: false, id: 8, answerId: '2-1',
            },
            {
                title: '终极吃货', sort: 1, vote: 0, isVote: false, id: 91, answerId: '2-2',
            },
            {
                title: '厨艺精湛', sort: 1, vote: 0, isVote: false, id: 92, answerId: '2-3',
            },
            {
                title: '社交达人', sort: 1, vote: 0, isVote: false, id: 10, answerId: '2-4',
            }
        ]
    },
    {
        title: '如果无聊猿与蝠鹿鱼四人组生活在中国，您觉得鱼会来自哪里？',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '北上广深', sort: 0, vote: 0, isVote: false, id: 11, answerId: '3-0',
            },
            {
                title: '东北', sort: 1, vote: 0, isVote: false, id: 12, answerId: '3-1',
            },
            {
                title: '江南', sort: 1, vote: 0, isVote: false, id: 13, answerId: '3-2',
            },
            {
                title: '港澳台', sort: 1, vote: 0, isVote: false, id: 14, answerId: '3-3',
            },
            {
                title: '川渝地区', sort: 1, vote: 0, isVote: false, id: 15, answerId: '3-4',
            },
            {
                title: '青藏高原', sort: 1, vote: 0, isVote: false, id: 16, answerId: '3-5',
            },
            {
                title: '大西北', sort: 1, vote: 0, isVote: false, id: 17, answerId: '3-6',
            }
        ]
    },
    {
        title: '如果无聊猿与蝠鹿鱼四人组生活在您的身边， 您觉得鱼的职业会是什么?',
        require: true,
        multiple: 1,
        type: 'choice',
        list: [
            {
                title: '喜剧演员', sort: 0, vote: 0, isVote: false, id: 18, answerId: '4-0',
            },
            {
                title: '漫画家', sort: 1, vote: 0, isVote: false, id: 19, answerId: '4-1',
            },
            {
                title: '主厨', sort: 1, vote: 0, isVote: false, id: 20, answerId: '4-2',
            },
            {
                title: '快递小哥', sort: 1, vote: 0, isVote: false, id: 21, answerId: '4-3',
            },
            {
                title: '经纪人', sort: 1, vote: 0, isVote: false, id: 22, answerId: '4-4',
            }
        ]
    },
    {
        title: '根据以上您选择的设定，您觉得鱼应该叫什么？类似无聊猿之类的名字，也可以是类似鱼XX的其他格式等（仅做参考，我们会根据性格设定挑选）',
        require: true,
        type: 'input',
        input: '',
        id: 99,
    },
    {
        title: '对于以上所有的问题，和其他没有问到的问题，欢迎在这里写下您的意见。',
        require: false,
        multlines: true,
        type: 'input',
        input: '',
        id: 100,
    }
]
export {
    FuQuestionList,
    luQuestionList,
    YuQuestionList,
    sequenceKeys
}
